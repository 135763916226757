import { Button, Icons } from '@healthinal/ui';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPatientDetailsCallcenterTransformedSuspense } from '../api/heurekaPatientDetails';
import ContentSection from '../components/ContentSection';
import HeurekaPatient from '../components/HeurekaPatient';
import { SharePatientDialog } from '../components/share-patient-dialog/SharePatientDialog';

export function PatientCallcenter() {
  const [showShareDialog, setShowShareDialog] = useState(false);

  const { patientId } = useParams();
  if (!patientId) {
    throw new Error('practice and patient id needs to be set');
  }
  const { data: patientData } = useGetPatientDetailsCallcenterTransformedSuspense(patientId);
  if (!patientData) {
    return null;
  }

  return (
    <ContentSection>
      <HeurekaPatient
        patientData={patientData}
        headerAction={
          import.meta.env.VITE_IS_PARTNER_FEATURE_ACTIVE === 'true' && (
            <Button
              startDecorator={<Icons.IosShare />}
              variant={'outlined'}
              color={'neutral'}
              onClick={() => setShowShareDialog(true)}>
              An Partnerpraxis freigeben
            </Button>
          )
        }
      />
      <SharePatientDialog patientId={patientId} open={showShareDialog} onClose={() => setShowShareDialog(false)} />
    </ContentSection>
  );
}
