import { styled, Typography } from '@healthinal/ui';
import 'animate.css';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from './components/error-handling/ErrorBoundary';
import Footer from './components/Footer';
import { SplashScreen } from './components/SplashScreen';
import { PatientCallcenter } from './pages/PatientCallcenter';

const Wrapper = styled('div')`
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr 20px;
`;

export default function App() {
  return (
    <ErrorBoundary isRoot>
      <Suspense fallback={<SplashScreen />}>
        <Wrapper>
          <Routes>
            <Route path="/callcenter/patient/:patientId" element={<PatientCallcenter />} />
            <Route path="*" element={<Fallback />} />
          </Routes>
          <Footer />
        </Wrapper>
      </Suspense>
    </ErrorBoundary>
  );
}

function Fallback() {
  return (
    <Typography>
      In der Callcenter Applikation können nur Patienten auf dem Pfad <code>{'/callcenter/patient/{patientId}'}</code>{' '}
      geladen werden.
    </Typography>
  );
}
