import {
  Button,
  FormControl,
  FormLabel,
  Icons,
  Modal,
  ModalClose,
  ModalDialog,
  ModalProps,
  Snackbar,
  Stack,
} from '@healthinal/ui';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { PracticeDto, useSharePatientWithPartner } from '../../api/generated';
import { DurationInDays, DurationSelector } from '../DurationSelector';
import { ModalTitle } from '../ModalTitle';
import { buttonLoadingIndicator } from '../buttonLoadingIndicator';
import { PartnerPracticeAutocomplete } from './PartnerPracticeAutocomplete';

interface Props extends Omit<ModalProps, 'children'> {
  patientId: string;
  onClose: () => void;
}

const DEFAULT_DURATION: DurationInDays = 14;

export function SharePatientDialog({ patientId, onClose, ...modalProps }: Props) {
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [durationInDays, setDurationInDays] = useState<DurationInDays>(DEFAULT_DURATION);
  const [practice, setPractice] = useState<PracticeDto | undefined>(undefined);

  const sharePatientWithPartnerMutation = useSharePatientWithPartner({
    mutation: {
      onSuccess: () => {
        setShowSuccessSnackbar(true);
        closeModal();
      },
      onError: () => setShowErrorSnackbar(true),
    },
  });

  const closeModal = () => {
    onClose();

    // Because this component is not unmounted when the dialog is closed,
    // we have to reset the state in case the dialog is opened a second time.
    setPractice(undefined);
    setDurationInDays(DEFAULT_DURATION);
  };

  return (
    <>
      <Modal {...modalProps} onClose={closeModal}>
        <ModalDialog>
          <ModalClose />
          <ModalTitle>Patient an Partnerpraxis freigeben</ModalTitle>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              sharePatientWithPartnerMutation.mutate({
                patientId,
                params: {
                  practiceId: practice!.id,
                  expiration: addDays(new Date(), durationInDays).toISOString(),
                },
              });
            }}>
            <Stack direction="row" gap={2}>
              <FormControl>
                <FormLabel>Freigeben für</FormLabel>
                <PartnerPracticeAutocomplete
                  patientId={patientId}
                  value={practice}
                  onChange={(_, value) => setPractice(value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Gültig für</FormLabel>
                <DurationSelector value={durationInDays} onChange={setDurationInDays} />
              </FormControl>
            </Stack>
            <Stack direction="row" gap={2} justifyContent="flex-end" mt={4}>
              <Button variant="plain" color={'neutral'} onClick={closeModal}>
                Abbrechen
              </Button>
              <Button type="submit" endDecorator={sharePatientWithPartnerMutation.isPending && buttonLoadingIndicator}>
                Freigeben
              </Button>
            </Stack>
          </form>
        </ModalDialog>
      </Modal>

      <Snackbar
        color="success"
        open={showSuccessSnackbar}
        onClose={() => setShowSuccessSnackbar(false)}
        autoHideDuration={2000}
        startDecorator={<Icons.Check />}>
        Patient freigegeben
      </Snackbar>
      <Snackbar
        color="danger"
        open={showErrorSnackbar}
        onClose={() => setShowErrorSnackbar(false)}
        autoHideDuration={3000}
        startDecorator={<Icons.ErrorOutline />}>
        Ein Fehler ist aufgetreten – Patient konnte nicht freigegeben werden.
      </Snackbar>
    </>
  );
}
